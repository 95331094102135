import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '@/views/LoginView.vue'
import HomeView from '@/views/HomeView.vue'
// import TestingView from '@/views/Testing.vue'

import SignUp from '@/views/SignUp.vue'
import DashboardView from '@/views/DashboardView.vue'
import BadAttendances from '@/views/BadAttendances.vue'


import LogoutView from '@/views/LogoutView.vue'


import DatabaseView from '@/views/DatabaseView.vue'
// import StudentDashboardView from '@/views/StudentDashboardView.vue'
import StudentDashboardView2 from '@/views/StudentDashboardView2.vue'

import InstructorDashboardView2 from '@/views/InstructorDashboardView2.vue'
import Hoursheet from '@/views/Hoursheet.vue'

import Meet from '@/views/Meet.vue'

import Profile from '@/views/Profile.vue'


import StudentCancellation from '@/views/StudentCancellation.vue'
import Whatsapp from '@/views/Whatsapp.vue'
import TermsAndConditions from '@/views/TermsAndConditions.vue'





const routes = [


  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta:{requiresAuth:false}
  },

  // {
  //   path: '/testing',
  //   name: 'testing',
  //   component: TestingView,
  //   meta:{requiresAuth:false}
  // },



  {
    path:'/admin-dashboard',
    name:'admin-dashboard',
    component:DashboardView,
    meta:{requiresAuth:true,requiresAdmin:true}
  },

  {
    path:'/bad-attendances',
    name:'bad-attendances',
    component:BadAttendances,
    meta:{requiresAuth:true,requiresAdmin:true}
  },


  {
    path:'/profile',
    name:'profile',
    component:Profile,
    meta:{requiresAuth:true}
  },


  {
    path:'/student-dashboard',
    name:'student-dashboard',
    component:StudentDashboardView2,
    meta:{requiresAuth:true}
  },


  {
    path:'/instructor-dashboard',
    name:'instructor-dashboard',
    component:InstructorDashboardView2,
    meta:{requiresAuth:true}
  },

  {
    path:'/hoursheet',
    name:'hoursheet',
    component:Hoursheet,
    meta:{requiresAuth:true}
  },



  {
    path:'/database',
    name:'database',
    component:DatabaseView,
    meta:{requiresAuth:true,requiresAdmin:true}
  },



  {
    path:'/signup',
    name:'signup',
    component:SignUp,
    meta:{requiresAuth:false}
  },

  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta:{requiresAuth:false}
  },
  {
    path:'/logout/',
    name:'logout',
    component:LogoutView,
    meta:{requiresAuth:false}
  },

  {
    path:'/meet/:link',
    name:'meet',
    component:Meet,
    meta:{requiresAuth:true}
  },

  {
    path:'/student_cancellation/',
    name:'student_cancellation',
    component:StudentCancellation,
    meta:{requiresAuth:true,requiresAdmin:true}
  },

  {
    path:'/whatsapp/',
    name:'whatsapp',
    component:Whatsapp,
    meta:{requiresAuth:true,requiresAdmin:true}
  },

  {
    path:'/Terms&Conditions/',
    name:'TermsAndConditions',
    component:TermsAndConditions,
    meta:{requiresAuth:true,requiresAdmin:true} //change to not require admin when ready
  },



  // MATERIAL STARTS HERE!

  {
    path:'/material1/',
    name: 'material1',
    component: material1,
    meta:{requiresAuth:true}
  },

    {
    path:'/vocab-lesson-plan/',
    name: 'vocab-lesson-plan',
    component: VocabLessonPlan,
    meta:{requiresAuth:true}
  },










]



import material1 from '@/views/material/material1.vue'
import VocabLessonPlan from '@/views/material/VocabLessonPlan.vue'

















const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes:routes,
})


import store from '@/store'

router.beforeEach(async (to,from,next)=>{

  if(to.meta.requiresAdmin){
    if (store.state.user.role=='Admin' && store.state.isAuthenticated){
      next()
      return
    }
    else{
      next('/')
      return
    }
  }



  if(to.meta.requiresAuth){
    if(store.state.isAuthenticated){
      next()
      return
    }else{
      next('/login/')
      return
    }
  }else{

    next()
    return
  }

  next({ ...to, replace: true }); 
  
})



export default router
