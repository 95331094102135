<template>
    <div class="title">
        {{ user.ui }}
    </div>

    <div class="container">


        <template v-if="data.groups">
            <div class="group" :class="{[group.instructor_status]:true,[group.student_status]:true }" v-for="group in data.groups">
                <div class="normal instructors">
                    <template v-for="instructor in group.instructor">
                        {{ instructor.ui }}
                    </template>
                </div>
                <div class="normal schedule">{{ group.schedule }}</div>
                <div class="subject">{{ group.subject }}</div>
                <label class="class_time">
                    Hora de Clase:
                    <div>
                        {{ group.current_attendance.time }}
                    </div>
                </label>
                <label class="duration">
                    Duración:
                    <template v-if="group.current_attendance.duration">
                      <div>{{ group.current_attendance.duration }} hrs</div>
                    </template>
                </label>
                <div class="buttons">
                    <template v-if="group.instructor_status=='confirmed'">
                        <button @click.stop="join_class(group)">
                            Unirse a clase
                        </button>
                        <button class="cancel-button" @click.stop="cancel_class(group)">
                            Cancelar Clase
                        </button>
                    </template>
                    <!-- <template v-else-if="group.status=='cancelled-by-student'">
                        Clase Cancelada Por Alumno
                    </template> -->
                    <template v-else-if="group.instructor_status=='cancelled-late'">
                        Clase Cancelada Tarde
                    </template>
                    <template v-else-if="group.instructor_status=='no-show'">
                        Clase No Asistida
                    </template>
                    <template v-else-if="group.instructor_status=='cancelled-on-time'">
                        Clase Cancelada a Tiempo
                    </template>
                    <template v-else-if="group.instructor_status=='rescheduled'">
                        Clase Reagendada
                    </template>
                    <template v-else>
                        Link de clase no generado
                    </template>
                </div>
            </div>

        </template>


    </div>

    <div class="title">
        Control de Clases para {{ user.ui }}
    </div>

    <div class="container payments">
        <template v-for="payment in data.payments">
            <div class="payment-container" :class="{paid:payment.paid}">
                <template v-for="package_ in payment.package">
                    <div class=payment>
                        <div>{{ package_.taken_hours }} / {{ package_.package_hours }}</div>
                        <div>Cantidad: {{payment.amount}}</div>
                        <template v-if="payment.paid">
                            <div>Fecha: {{payment.datetime}}</div>
                        </template>
                    </div>
                    <table class="package">
                        <thead>
                            <th>Fecha</th>
                            <th>Instructor</th>
                            <th>Alumno</th>
                            <th>Estatus</th>
                            <th>Duración</th>
                        </thead>
                        <tbody>
                            <tr v-for="attendance in package_.attendance">
                                <td>{{ attendance.datetime }}</td>
                                <td>
                                  <template v-if="attendance.instructor_confirmation">
                                    {{ attendance.instructor_confirmation.at(-1).instructor.ui }} 
                                  </template>
                                </td>
                                <td>{{ attendance.student_confirmation.at(-1).student.ui }}</td>
                                <td>{{ attendance.student_confirmation.at(-1).confirmation }}</td>
                                <td>{{attendance.duration}}</td>
                            </tr>
                        </tbody>
                    </table>
                </template>
            </div>
        </template>
    </div>
</template>


<script setup>
    import { ref, reactive, onBeforeMount,onMounted} from 'vue'
    import axios from 'axios'
    import {useStore} from 'vuex'
    import {useRoute,useRouter} from 'vue-router'

    let router = useRouter()

    let route=useRoute()


    let store=useStore()
    let user=store.state.user

    let data=reactive({
        groups:[],
        user:{},
        payments:[],
    })


    onBeforeMount(async ()=>{
        if (user.role.includes('Admin')){
            let user_id=route.query.user_id
            
            let response= await axios.post('/admin/get_user/',{user_id:user_id})
            user=response.data
            data.user=user

        }

        get_groups()
        get_payments()
        setInterval(()=>{
            if(route.name.includes('student-dashboard')){
                update_groups()
            }

        },10000)

    })


    async function get_payments(){
        let response=await axios.post('/students/get_payments/',{user_id:user.id})
        Object.assign(data.payments,response.data)
    }

    async function get_groups(){
        let response=await axios.post('/students/get_groups/',{user_id:user.id})
        Object.assign(data.groups,response.data)

    }

    async function update_groups(){
        let response = await axios.post('/students/get_groups/',{user_id:user.id})
        let groups=response.data        
        for ( let key in data.groups){
            for (let element of groups){
                if (element.id==data.groups[key].id){
                let status_show=data.groups[key].status_window_show
                let status_hide=data.groups[key].status_window_hide
                let current_attendance=data.groups[key].current_attendance

                Object.assign(data.groups[key],element)
                Object.assign(data.groups[key].current_attendance,{show:current_attendance.show,hide:current_attendance.hide})

                }
            }

        }
    }


    function join_class(group){
        let attendance_link=group.current_attendance.link
        window.location.href=attendance_link
        // router.push({ name: 'meet',params:{link:attendance_link}});
    }

    function cancel_class(group){
        window.location.href=`/student_cancellation?user_id=${data.user.id}&group_id=${group.id}`
    }


</script>


<style scoped>

.container{
    width:100%;
    max-width: 100vw;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    background:var(--darkblue);
    color: var(--white);
  }

  .container:nth-of-type(2n){
    background:var(--darkyellow);
  }

  .group{
    display: grid;
    min-width: 50vw;
    width: fit-content;
    max-width: 100vw;
    border-radius: 5px;
    border: var(--border);
    text-align: center;
    text-justify: center;
    background:var(--gray);
    color: var(--black);
    overflow:auto;


    grid-template-areas: "instructors subject"
                        "schedule subject"
                        "status status"
                        "class_time class_time"
                        "duration duration"
                        "buttons buttons";
  }

  /* .group>*{
    border-bottom: var(--border);
  } */


  .normal{
    background: var(--white);
  }
  .instructors{
    display:flex;
    justify-content:center;
    align-items:center;
    grid-area: instructors;
    min-width:max-content;
    font-size: x-large;

    border-right: var(--border);


  }
  .subject{
    display:flex;
    justify-content:center;
    align-items:center;
    grid-area:subject;
    font-size: xx-large;
    border-bottom: var(--border);
  }
  .schedule{
    display:flex;
    justify-content:center;
    align-items:center;
    grid-area:schedule;
    min-width:max-content;
    font-size: x-large;

    border-right: var(--border);
    border-bottom: var(--border);


  }
  .status{
    display:flex;
    justify-content:center;
    align-items:center;
    grid-area:status;
    font-size: x-large;

  }
  .class_time{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    grid-area:class_time;
    font-size: x-large;

  }
  .duration{
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    grid-area:duration;
    font-size: x-large;
}
  .buttons{
    display:flex;
    justify-content:center;
    align-items:center;
    grid-area: buttons;
    font-size: xx-large;
    gap:10px;

  }

  .cancel-button{
    background:var(--red);
  }



  .confirmed{
    background:var(--green);
  }
  .cancelled-late{
    background:var(--red);
  }
  .cancelled-late-by-student{
    background:var(--red);
  }

  .no-show{
    background:var(--darkred);
  }

  .cancelled-on-time{
    background: var(--lightblue);
  }

  .cancelled-on-time-by-student{
    background: var(--lightblue);
  }


  .rescheduled{
    background: var(--gray);
  }

  .cancelled_by_student{
    background: var(--gray);
  }


  .payments{
    gap:1rem;
    overflow:auto;
    padding-top:1rem;
  }

  .payment-container{
    background:var(--lightred);
    display:flex;
    flex-direction:column;
    min-width:max-content;
    padding:0 20px 5px 20px;

  }



  .payment{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
  }

 

  .package{
    min-width:500px;
    width:fit-content;
    max-width:500px;
    overflow:auto;
    background: var(--light);
    color:var(--black);
  }


  .paid{
    background:var(--green);
  }


  tr{
    border:var(--border);
  }


  td {
  white-space: nowrap; /* Prevents text from wrapping to the next line */
  width: min-content;  /* Sets the width to the minimum necessary to contain the content */
}

  .group{
    height:300px;
  }







</style>