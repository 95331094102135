<template>

    <div class="communications">
        <div class="contacts">
            <div @click.stop="get_messages(contact)" class="contact" :class="{selected:contact==data.selected_contact}" v-for="contact in data.contacts">
                {{ contact.number }}
            </div>
    
        </div>
        <div class="messages">
            <div class="message" :class="{left:message.direction.includes('inbound'),right:message.direction.includes('outbound')}" v-for="message in data.selected_contact.messages">
                <template v-if="message.direction.includes('inbound')">
                    Student: {{ message.body }}

                </template> 
                <template v-else>
                    InterAct: {{ message.body }}
                </template>
            </div>
        </div>
        <div class="message-input">
            <input @keypress.enter.stop="send_message()" v-model="data.selected_contact.pending_message" placeholder="Write message here">
        </div>
    </div>

</template>



<script setup>

    import {reactive, onBeforeMount, resolveComponent} from 'vue'
    import axios from 'axios'

    let data=reactive({
        contacts:[],
        selected_contact:{
            pending_message:''
        },

    })

    onBeforeMount(async ()=>{
        let response=await axios.post('/twilio/get_contacts/')
        data.contacts=response.data


    })


    async function get_messages(contact){
        let response=await axios.post('/twilio/get_messages/',{contact:contact})

        contact.messages=response.data
        data.selected_contact=contact
        console.log(contact.messages)

    }
    
    async function send_message(){
        let body=data.selected_contact.pending_message
        let number=data.selected_contact.number

        let response=await axios.post('/twilio/send_message/',{number:number,body:body})
        message=response.data
        data.selected_contact.messages.push(message)
    }

</script>


<style scoped>

    .communications{
        display: grid;
        width: 100%;
        max-width: 100vw;
        height: 100%;
        max-height: 100vh;

        grid-template-areas: "contacts messages"
                            "contacts input";

        grid-template-rows: 1fr 5rem;
        grid-template-columns: 1fr 3fr;
        overflow: hidden;
    }

    .contacts{
        display: flex;
        flex-direction: column;
        
        width: 100%;
        min-width: max-content;
        
        height: 100vh;
        max-height: 100vh;
        
        align-items: center;
        justify-content: start;

        grid-area: contacts;

        background:var(--gray);

        padding-left:1rem;

    }

    .contact{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: x-large;
        background:var(--white);
        min-width: 12rem;
        width: 100%;
        max-width: 100%;
        height: 5rem;
        overflow: auto;
        cursor: pointer;
        /* margin-left: 1rem; */

        border:var(--border);

    }

    .selected{
        background: var(--darkgreen);
        color:var(--white);
    }

    .message-input{
        width: 100%;
        height: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        grid-area:input;
    }

    .message-input input{
        width: 100%;
    }

    .messages{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 100%;
        min-width: min-content;
        gap:10px;
        height: 90vh;
        max-height: 100vh;
        align-items: center;
        justify-content: end;
        overflow-y: auto;
        grid-area: messages;
    }

    .messages {
    grid-area: messages;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%; /* Fills available height of the grid cell */
    max-height: calc(100vh - 5rem); /* Limits height, with scroll if content overflows */
    overflow-y: auto; /* Enables vertical scrolling */
    padding: 1rem; /* Optional: add padding for better readability */
}


    .left{
        align-self: start;

    }

    .right{
        align-self:end;
    }




</style>